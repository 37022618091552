import React, { useState } from 'react';
import { STATIC_FILE_URL } from '@/shared/constants';
import { useRouter } from 'next/navigation';
import { NavMenuItem } from '@/types/layout';
import Image from 'next/image';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import './index.less';

interface IProps {
  openLeftMenuDrawerFn: () => void;
  setActiveMenuItemIndex: (i: number) => void;
  menuItem: NavMenuItem;
  menuItemIndex: number;
  isIconHoverable?: boolean;
}

export default function PlatformMenu({
  setActiveMenuItemIndex,
  openLeftMenuDrawerFn,
  menuItemIndex,
  menuItem,
  isIconHoverable = true,
}: IProps) {
  const [isAnimatedWandActive, setIsAnimatedWandActive] = useState<boolean>(false);

  const router = useRouter();

  const renderAnimatedWandImage = (
    <div className="wand-container">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={`${STATIC_FILE_URL}/7c6124e5962fc8d482cbdcf1e43a2b95.svg`}
        width={32}
        height={32}
        alt="Pietra Ideation"
        className="wand"
      />
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={`${STATIC_FILE_URL}/ca69556ba5534450ada324258acd2dab.svg`}
        width={32}
        height={32}
        alt="Pietra Ideation"
        className="stars"
      />
    </div>
  );

  const renderIcon = () => {
    if (menuItem?.isAnimatedWand) {
      return (
        <div className="icon-container">
          {isAnimatedWandActive && isIconHoverable ? (
            renderAnimatedWandImage
          ) : (
            <Image src={menuItem.icon} width={32} height={32} alt={menuItem.title} className="icon" />
          )}
        </div>
      );
    }

    return (
      <div className={classNames('icon-container', { hoverable: !isEmpty(menuItem?.hoverIcon) })}>
        <Image src={menuItem.icon} width={32} height={32} alt={menuItem.title} className="icon" />
        {menuItem?.hoverIcon && (
          <Image src={menuItem.hoverIcon} width={32} height={32} alt={menuItem.title} className="hover-icon" />
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames('nav-menu-item', menuItem.class, { isIconHoverable })}
      key={menuItem.title}
      onClick={() => {
        openLeftMenuDrawerFn();
        setActiveMenuItemIndex(null);
        router.push(menuItem.path);
      }}
      onMouseEnter={() => {
        setActiveMenuItemIndex(menuItemIndex);

        if (menuItem?.isAnimatedWand) {
          setIsAnimatedWandActive(true);
        }
      }}
      onMouseLeave={() => {
        setActiveMenuItemIndex(null);

        if (menuItem?.isAnimatedWand) {
          setIsAnimatedWandActive(false);
        }
      }}
    >
      <div className="title-container">
        {renderIcon()}
        <span>{menuItem.title}</span>
      </div>
      <span className="description">{menuItem.description}</span>
    </div>
  );
}
