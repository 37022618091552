import React from 'react';
import MenuItem from 'src/components/Common/Layout/HeaderMenu/MenuItem';
import NavMenuCaseStudy from '../NavMenuCaseStudy';
import { DROPDOWN_MENU_CONTENT } from '@/shared/constants/nav-constants';
import { NavMenuItem, UseCasesMenuSection } from '@/types/layout';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
}

export default function PlatformMenu({ closeActiveMenu }: IProps) {
  const useCases = DROPDOWN_MENU_CONTENT.useCases.sections;
  const caseStudy = DROPDOWN_MENU_CONTENT.useCases.caseStudy;

  const renderUseCasesContent = useCases.map((section: UseCasesMenuSection) => (
    <div className="use-cases-section" key={section.title} onClick={() => closeActiveMenu()}>
      <div className="title-container">
        <span className="title">{section.title}</span>
      </div>
      {section.items.map((item: NavMenuItem, i: number) => {
        return (
          <MenuItem
            key={item.title}
            menuItem={item}
            menuItemIndex={i}
            setActiveMenuItemIndex={(i: number) => null}
            openLeftMenuDrawerFn={() => null}
          />
        );
      })}
    </div>
  ));

  return (
    <div
      className="nav-use-cases-menu"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div className="menu-content">
        <div className="use-cases-container">{renderUseCasesContent}</div>
        <div className="case-study-container" onClick={() => closeActiveMenu()}>
          <NavMenuCaseStudy caseStudy={caseStudy} hasWhiteText={false} />
        </div>
      </div>
    </div>
  );
}
