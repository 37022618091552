import React, { useState } from 'react';
import AnimatedPillarsIcon from '../AnimatedPillarsIcon';
import MenuItem from 'src/components/Common/Layout/HeaderMenu/MenuItem';
import { DROPDOWN_MENU_CONTENT } from '@/shared/constants/nav-constants';
import FreePietraApps from '../FreePietraApps';
import './index.less';

interface IProps {
  closeActiveMenu: () => void;
}

export default function PlatformMenu({ closeActiveMenu }: IProps) {
  const [activePillarIndex, setActivePillarIndex] = useState<number>(null);

  const pillars = DROPDOWN_MENU_CONTENT.platform.pillars;

  return (
    <div
      className="nav-platform-menu"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div className="menu-content">
        <div className="pillars-container">
          <div className="title-container">
            <AnimatedPillarsIcon activePillarIndex={activePillarIndex} />
            <span className="title">Pietra Platform</span>
            <span className="description">
              All the necessary software and infrastructure you need to build your brand.
            </span>
          </div>
          <div className="pillars" onClick={() => closeActiveMenu()}>
            {pillars.map((pillar, i: number) => {
              return (
                <MenuItem
                  key={pillar.title}
                  menuItem={pillar}
                  menuItemIndex={i}
                  openLeftMenuDrawerFn={() => null}
                  setActiveMenuItemIndex={setActivePillarIndex}
                />
              );
            })}
          </div>
        </div>
        <div className="apps-container">
          <FreePietraApps apps={DROPDOWN_MENU_CONTENT.platform.apps} closeActiveMenu={closeActiveMenu} />
        </div>
      </div>
    </div>
  );
}
